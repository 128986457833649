import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  RouterEvent,
  RouterModule,
  Event,
  Router,
  NavigationEnd,
} from '@angular/router';
import { MainService } from '../../services';
import { Subscription, filter } from 'rxjs';
import { EventBusService, TokenStorageService } from 'src/app/core/services';
import { Events } from 'src/app/core/enums';
import { EmitEvent } from 'src/app/core/helpers';
import { NotificationService } from '../../services/notification.service';
import { PaginationConfig } from '../../enums';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  classToggled = false;
  @Output() toggleSidebar: EventEmitter<boolean> = new EventEmitter<boolean>(
    false
  );
  private subscription: Subscription = new Subscription();
  public profileDetail: any;

  public notifyList: any = [];
  public notifyCount: any = 0;

  public filter = {
    sortBy: '',
    orderBy: 'DESC',
  };
  public paginationConfig: PaginationConfig = {
    totalItems: 0,
    itemsPerPage: 20,
    currentPage: 1,
  };

  constructor(
    private mainService: MainService,
    private tokenStorageService: TokenStorageService,
    private eventBusService: EventBusService,
    private notifiyService: NotificationService,
    private router: Router
  ) {}
  logout() {
    this.eventBusService.emit(new EmitEvent(Events.SIGNOUT));
  }
  ngOnInit(): void {
    this.subscription.add(this.mainService.httpgetprofile().subscribe());
    this.subscription.add(
      this.mainService.profileDetail$.subscribe((res) => {
        this.profileDetail = res ? res : '';
      })
    );

    this.subscription.add(
      this.router.events
        .pipe(
          filter(
            (e: Event | RouterEvent): e is RouterEvent =>
              e instanceof NavigationEnd
          )
        )
        .subscribe((e: RouterEvent) => {
          this.classToggled = false;
          this.toggleSidebar.emit(this.classToggled);
        })
    );
    // this.getNotifyList();
  }

  getNotifyList() {
    this.subscription.add(
      this.notifiyService
        .httpGetNotificationList(
          this.paginationConfig.itemsPerPage,
          this.paginationConfig.currentPage
        )
        .subscribe({
          next: ({ data }) => {
            this.notifyList =
              data && data.notifications ? data.notifications : [];
            this.notifyCount = data?.count;
          },
        })
    );
  }

  readNotify(item: any, type: string) {
    this.subscription.add(
      this.notifiyService.httpUpdateNotifyStatus(item.id).subscribe({
        next: ({ data }) => {
          if (data && data.code) {
            this.getNotifyList();
            if (
              item.notification_recieved.event_type.toLowerCase() === 'refund'
            ) {
              this.router.navigate([
                'panel/manage-ride/manage-refund-process/edit-refund-process',
                item.notification_recieved.booking_id,
              ]);
            } else {
              this.router.navigate(['panel/notification/detail', item.id]);
            }
          }
        },
      })
    );
  }

  public toggleField() {
    this.classToggled = !this.classToggled;
    this.toggleSidebar.emit(this.classToggled);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
