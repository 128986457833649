import { Component } from '@angular/core';
import { FooterComponent } from '../../footer/footer.component';
import { CmsHeaderComponent } from '../cms-header/cms-header.component';

@Component({
  selector: 'app-confirmation-page',
  standalone: true,
  imports: [FooterComponent,CmsHeaderComponent],
  templateUrl: './confirmation-page.component.html',
  styleUrls: ['./confirmation-page.component.scss']
})
export class ConfirmationPageComponent {

}
